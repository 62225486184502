import React from 'react';

import LinkCTA from 'Components/common/linkCTA';

import { strings } from 'Constants/strings';

type OwnProps = {
	to?: string;
	target?: string;
	href?: string;
	text?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ViewAllLink.defaultProps;

// @ts-expect-error TS(7022): 'ViewAllLink' implicitly has type 'any' because it... Remove this comment to see the full error message
const ViewAllLink = ({ to, target, text }: Props) => (
	<LinkCTA
		as='anchor'
		prefetch={false}
		href={to}
		rel='noopener noreferrer'
		target={target ?? to}
		text={text || strings.CMN_SEE_ALL}
		data-testid='view-all-link-cta'
	/>
);

ViewAllLink.defaultProps = {
	target: '_blank',
};

export default ViewAllLink;
